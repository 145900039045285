
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseYavagroDirectionCard from '~/components/UI/BaseYavagroDirectionCard.vue'
import { DirectionCardModel } from '~/model'

@Component({
  components: {
    BaseYavagroDirectionCard
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      id: '',
      name: '',
      background: '',
      image: '',
      href: ''
    })
  })
  readonly directionCard!: DirectionCardModel

  handleCardClick() {
    this.$emit('onCardClick')
  }
}
