
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DirectionCard from './DirectionCard.vue'
import { ContainerOptions, DirectionCardModel } from '~/model'
import PageContainer from '~/components/Containers/PageContainer.vue'
import SwiperContainer from '~/components/Containers/SwiperContainer.vue'

@Component({
  components: {
    DirectionCard,
    SwiperContainer,
    PageContainer
  }
})
export default class extends Vue {
  @Prop({
    default: () => []
  })
  readonly directionCards!: DirectionCardModel[]

  @Prop({
    default: () => []
  })
  readonly containerOptions!: ContainerOptions

  @Prop({
    default: 4
  })
  readonly numberOfColumns!: Number

  @Prop({
    default: false
  })
  readonly isSmall!: boolean

  sliderOptions = {
    loop: false,
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 10,
    pagination: true
  }

  onCardClick(href) {
    this.$router.push(href)
  }
}
