
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseRabotaAgroIconButton from '@rshb/rshb-vue-kit/src/projects/rabotaAgro/buttons/BaseRabotaAgroIconButton.vue'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import { ContainerOptions } from '~/model'

@Component({
  components: {
    SvoiButtonLink,
    BaseRabotaAgroIconButton
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      highlightedBackground: false,
      centered: false,
      link: false,
      subtitle: '',
      name: ''
    })
  })
  readonly options!: ContainerOptions

  onClickButton() {
    window.open(this.options?.button?.href, '_self')
  }
}
